.x-select
  display: block
  position: relative
  &__arrow
    display: block
    color: var(--icon-dark-50)
    width: 0
    height: 0
    border-style: solid
    border-width: 4px 4px 0 4px
    border-color: currentColor transparent transparent transparent
  &__control
    position: relative
  &__dropdown
    display: none
    position: absolute
    z-index: 1000
    top: 100%
    left: 0
    width: 100%
    box-sizing: border-box
    background-color: var(--input-white)
    border: 1px solid var(--input-focus)
    margin-top: 4px
    padding: 3px 0
    border-radius: var(--base-radius)
    &-search
      padding: 0 3px 4px
    &-options
      overflow: auto
      max-height: 290px
    &[hidden]
      display: none !important

  &.-state-open &
    &__arrow
      color: var(--icon-primary)
      transform: rotate(180deg)
    &__dropdown
      display: block
