import { Component, Host, State, Prop, Element, h, forceUpdate, Watch } from '@stencil/core';
import clsx from 'clsx';
// import tippy from 'tippy.js';

@Component({
  tag: 'x-select',
  styleUrl: 'x-select.sass',
})
export class XSelect {
  @Element() el: HTMLElement;

  @Prop() label: string;
  @Prop() name: string;
  @Prop() fieldHandleId: string;
  @Prop() placeholder: string;
  @Prop() multiple: boolean = false;
  @Prop() helper: string;
  @Prop() invalid: boolean;
  @Prop() disabled: boolean;
  @Prop() searchable: boolean;
  @Prop() autocomplete: boolean;
  @Prop() input: boolean = false;

  @State() isOpen: boolean = false;
  @State() value: any[] = [];
  @State() caption: string;
  @State() search: string = '';
  @State() searchValue: string = '';
  @State() focusedOption: number = -1;

  @Watch('search')
  filterOptions() {
    const options = this.el.querySelectorAll('x-select-option');
    const searchString = this.search.toLowerCase();

    this.searchValue = this.search;

    Array.prototype.forEach.call(options, option => {
      const optionValue = option.getAttribute('value').trim();
      const optionLabel = option.querySelector('.x-select-option__value').innerText.trim();

      if (searchString.trim() === '') {
        option.querySelector('.x-select-option__value').innerHTML = optionLabel;
        option.removeAttribute('hidden');
      } else {
        const foundInValue = optionValue.toLowerCase().search(searchString) !== -1;
        const foundInLabel = optionLabel.toLowerCase().search(searchString) !== -1;

        if (!foundInValue && !foundInLabel) {
          option.setAttribute('hidden', '');
        } else {
          const expr = new RegExp(searchString, 'gi');
          const highlighted = foundInLabel ? optionLabel.replace(expr, match => `<mark>${match}</mark>`) : optionLabel;

          option.querySelector('.x-select-option__value').innerHTML = highlighted;
          option.removeAttribute('hidden');
        }
      }
    });
  }

  private valueInput?: HTMLInputElement;

  private activate = () => {
    this.isOpen = true;

    if (this.searchable) {
      setTimeout(() => {
        (this.el.querySelector('.x-select__dropdown-search input') as HTMLInputElement).focus();
      });
    }
  };

  private deactivate = () => {
    this.isOpen = false;
  };

  private autohide = e => {
    if (e.target !== this.el && !this.el.contains(e.target)) {
      this.deactivate();
    }
  };

  // private collectValues = () => {
  //   const selectedOptions = this.el.querySelectorAll('x-select-option[selected]');
  //   console.log(selectedOptions);

  //   this.value = Array.prototype.map.call(selectedOptions, option => {
  //     return {
  //       value: option.getAttribute('value'),
  //       label: option.innerText.trim(),
  //     };
  //   });
  // };

  private handleUpdate = e => {
    const options = Array.from(this.el.querySelectorAll('x-select-option'));

    let nextValue;

    const toggleSelected = () => {
      for (const option of options) {
        if (option !== this.el) {
          if (e.target.selected) {
            option.setAttribute('selected', '');
          } else {
            option.removeAttribute('selected');
          }
        }
      }
    };

    // Мультиселект
    if (this.multiple) {
      // Клик на опцию мультивыбора "All"
      if (e.target.toggleAll) {
        // Присваиваем value значение All
        if (e.target.selected) {
          nextValue = [
            {
              value: e.target.value,
              label: e.target.innerText.trim(),
              caption: e.target.caption,
            },
          ];
        } else {
          // Если опция не выбрана, очищаем значение селекта
          nextValue = [];
        }

        // Перебираем все опции и делаем их активными/неактивными
        // в зависимости от состояния опции All.
        //
        // Баг WC-2
        // При загрузке страницы x-select инициализируется раньше,
        // чем x-select-option (иерархия вложенности), поэтому следующее действие
        // вызывает нежелательный emit от x-select-option. Опции считают, что атрибут
        // selected добавлен к ним изначально, а не динамически. Из-за этого value селекта
        // оказывается неверным (все значения оказываются перечислены, вместо того чтобы показать одно all).
        // Поэтому оборачиваем перебор в setTimeout, чтобы он произошёл в следующем фрейме,
        // когда опции уже успеют инициализироваться.
        e.detail.initial ? setTimeout(toggleSelected) : toggleSelected();
      }
      // Клик на обычную опцию
      else {
        let hasDeselected; // Есть ли неактивные опции?

        // Перебираем все опции и оставляем в value только активные.
        // Если вручную выбраны все опции, опция All должна стать активной автоматически,
        // и наоборот: если выбрано всё и юзер снимает выбор с одной из опций,
        // опция All должна стать неактивной.
        //
        // Массив опций перед перебором разворачиваем,
        // чтобы оптимизировать алгритм работы опции All: визуально она всегда идёт первой,
        // а чтобы учесть логику её работы ↑, нам нужно дойти до неё в последнюю очередь.
        // Поэтому наполняем массив value в обратнм порядке.
        nextValue = options.reverse().reduce((result, item) => {
          // Когда дошли до опции All
          if (item.toggleAll) {
            // Если она активна, но есть хотя бы одна неактивная опция,
            // делаем её неактивной
            if (item.selected && hasDeselected) {
              item.removeAttribute('selected');
            }
            // И наоборот, если она не выбрана, а все остальные опции активны,
            // делаем её активной.
            // Вместе с этим подменяем value, чтобы там были перечислены
            // не все опции подряд, а было просто значение All
            else if (!item.selected && !hasDeselected) {
              item.setAttribute('selected', '');
              result = [
                {
                  value: item.value,
                  label: item.innerText.trim(),
                  caption: item.caption,
                },
              ];
            }
          }
          // Работаем с обычными опциями
          else {
            // Добавляем в value активные опции
            if (item.selected) {
              result.unshift({
                value: item.value,
                label: item.innerText.trim(),
                caption: item.caption,
                chip: item.chip,
              });
            } else {
              // И если хотя бы одна не актива, делаем пометку об этом
              // для обеспечения правильной работы опции All
              hasDeselected = true;
            }
          }

          return result;
        }, []);
      }

      // Если это селект с автокомплитом, возвращаем фокус в инпут и очищаем его при выборе опции
      if (this.autocomplete && !e.detail.initial) {
        const input = this.el.querySelector('.x-field-handle__control-input') as HTMLInputElement;

        if (input) {
          input.focus();
        }

        this.search = '';
      }
    }

    // Одиночный селект
    if (!this.multiple) {
      this.search = '';
      this.searchValue = this.autocomplete ? e.detail.label : '';
      nextValue = [e.detail];
      this.deactivate();

      for (const option of options) {
        if (option !== e.target) {
          option.removeAttribute('selected');
        }
      }
    }

    this.value = nextValue;
    this.valueInput.value = nextValue.map(item => item.value).join(',');
    // forceUpdate(this.el);

    setTimeout(() => {
      this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
    });
  };

  private handleSearch = e => {
    this.search = e.target.value;
  };

  private handleAddCustomChip = e => {
    const dropdown = this.el.querySelector('x-select-dropdown');
    const option = document.createElement('x-select-option');

    option.innerHTML = e.detail;
    option.setAttribute('value', e.detail);
    option.setAttribute('chip', 'blue-gray');
    option.setAttribute('selected', '');
    option.setAttribute('custom', '');
    if (this.multiple) option.setAttribute('multiple', '');
    if (this.autocomplete) option.setAttribute('autocomplete', '');

    this.value.push({ value: e.detail, label: e.detail, caption: undefined, selected: true, chip: 'blue-gray' });
    this.valueInput.value = this.value.map(item => item.value).join(',');
    this.search = '';

    dropdown.appendChild(option);
    forceUpdate(this.el);

    setTimeout(() => {
      this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
    });
  };

  // private handleAddCustomChip = e => {
  //   if (this.multiple) {
  //     this.value.push({ value: e.detail, label: e.detail, caption: undefined, selected: true, chip: 'blue-gray' });
  //     this.valueInput.value = this.value.map(item => item.value).join(',');
  //     this.search = '';
  //     forceUpdate(this.el);

  //     setTimeout(() => {
  //       this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
  //     });
  //   } else {
  //     this.isOpen = false;
  //     this.handleCustomACValue(e);
  //   }
  // };

  private handleDeleteChip = e => {
    const deletedValue = this.value.splice(e.detail, 1)[0];
    const option = this.el.querySelector(`x-select-option[value="${deletedValue.value}"]`);

    if (option) {
      option.removeAttribute('selected');

      if (option.getAttribute('custom') !== null) {
        option.parentNode.removeChild(option);
      }
    }

    forceUpdate(this.el);

    setTimeout(() => {
      this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
    });
  };

  private handleCustomACValue = e => {
    const options = Array.from(this.el.querySelectorAll('x-select-option'));

    this.value = e.detail ? [{ value: e.detail, label: e.detail, caption: undefined, selected: true }] : [];
    this.valueInput.value = e.detail ? this.value.map(item => item.value).join(',') : '';
    this.search = '';
    this.searchValue = e.detail;

    for (const option of options) {
      if ((option.querySelector('.x-select-option__value') as any).innerText.trim() === e.detail) {
        option.setAttribute('selected', '');
      } else {
        option.removeAttribute('selected');
      }
    }

    forceUpdate(this.el);

    setTimeout(() => {
      this.valueInput.dispatchEvent(new Event('change', { bubbles: true }));
    });
  };

  private handleKeyUp = e => {
    const keyCode = e.keyCode || e.charCode;

    if (this.isOpen && keyCode === 27) {
      e.stopPropagation();
      this.deactivate();
    }
  };

  private handleArrows = e => {
    if (!this.isOpen || this.input) {
      return;
    }

    const keyCode = e.keyCode || e.charCode;

    if (keyCode === 38 || keyCode === 40) {
      e.preventDefault();

      const options = this.el.querySelectorAll('x-select-option');
      const maxIndex = options.length - 1;

      if (this.focusedOption === -1) {
        this.focusedOption = keyCode === 38 ? maxIndex : 0;
      } else {
        if (keyCode === 38) {
          this.focusedOption--;
        } else {
          this.focusedOption++;
        }

        if (this.focusedOption < 0) {
          this.focusedOption = maxIndex;
        } else if (this.focusedOption > maxIndex) {
          this.focusedOption = 0;
        }
      }

      options[this.focusedOption].focus();
    }
  };

  private handleReset = () => {
    const options = this.el.querySelectorAll('x-select-option');
    const isAllSelected = this.el.querySelector('x-select-option[toggle-all][defaultSelected]') !== null;
    const nextValue = [];

    Array.from(options).forEach(option => {
      const input = option.querySelector('input');
      const isDefaultSelected = option.getAttribute('defaultselected') !== null;
      const shouldBeSelected = isAllSelected || isDefaultSelected;

      option.selected = shouldBeSelected;

      if (input) {
        setTimeout(() => {
          option.querySelector('input').checked = shouldBeSelected;
        });
      }

      if (isDefaultSelected) {
        nextValue.push({
          initial: true,
          value: option.getAttribute('value'),
          label: (option.querySelector('.x-select-option__value') as any).innerText.trim(),
          caption: undefined,
          selected: true,
          chip: option.getAttribute('chip'),
        });
      }
    });

    this.value = nextValue;
    this.searchValue = '';

    // https://youtrack.x-cloud.com/issue/WC-3
    if (!this.multiple && nextValue.length) {
      setTimeout(() => {
        this.searchValue = nextValue[0].label;
      });
    }

    forceUpdate(this.el);
  };

  componentWillLoad() {
    const form = this.el.closest('form');

    this.el.childNodes.forEach(element => {
      element['multiple'] = this.multiple;
      element['autocomplete'] = this.autocomplete;
    });

    if (form) {
      form.addEventListener('reset', this.handleReset);
    }
  }

  componentDidLoad() {
    window.addEventListener('mousedown', this.autohide);
    window.addEventListener('keydown', this.handleArrows);

    // setTimeout(() => {
    //   this.collectValues();
    // });
  }

  // componentWillLoad() {
  //   // this.tip = tippy(this.el, {
  //   //   content: reference => {
  //   //     console.log(this.el.querySelector('.x-select__dropdown'));
  //   //     return this.el.querySelector('template');
  //   //     // const id = reference.getAttribute('data-template');
  //   //     // const template = document.getElementById(id);
  //   //   },
  //   //   allowHTML: true,
  //   //   interactive: true,
  //   //   trigger: 'click',
  //   // });
  // }

  disconnectedCallback() {
    window.removeEventListener('mousedown', this.autohide);
  }

  render() {
    return (
      <Host class={clsx('x-select', { '-state-open': this.isOpen })} onUpdate={this.handleUpdate} onKeyUp={this.handleKeyUp}>
        {/* {JSON.stringify(this.value)} */}
        {!!this.label && <x-field-label disabled={this.disabled}>{this.label}</x-field-label>}
        <input type="hidden" name={this.name} value={this.value.map(item => item.value).join(',')} ref={el => (this.valueInput = el as HTMLInputElement)} id={this.fieldHandleId} />
        <div class="x-select__control">
          <x-field-handle
            type="select"
            value={this.value.slice(0)}
            placeholder={this.placeholder}
            caption={this.caption}
            active={this.isOpen}
            invalid={this.invalid}
            autocomplete={this.autocomplete}
            multiple={this.multiple}
            disabled={this.disabled}
            onActivate={this.activate}
            onDeactivate={this.deactivate}
            onInput={this.handleSearch}
            onAddCustomChip={this.handleAddCustomChip}
            onDeleteChip={this.handleDeleteChip}
            onCustomValue={this.handleCustomACValue}
            searchValue={this.searchValue}
          >
            {!this.autocomplete && (
              <x-input-adornment position="end" slot="adornment-end">
                <div class="x-select__arrow"></div>
              </x-input-adornment>
            )}
          </x-field-handle>
          <div class="x-select__dropdown" hidden={this.input}>
            {!!this.searchable && (
              <div class="x-select__dropdown-search">
                <x-input type="search" placeholder="Поиск" value={this.search} onInput={this.handleSearch}>
                  <x-input-adornment position="end">
                    <x-icon glyph="search" size="16" color="secondary"></x-icon>
                  </x-input-adornment>
                </x-input>
              </div>
            )}

            <div class="x-select__dropdown-options">
              <x-select-dropdown>
                <slot></slot>
              </x-select-dropdown>
            </div>
          </div>
        </div>
        {!!this.helper && <x-field-helper invalid={this.invalid}>{this.helper}</x-field-helper>}
      </Host>
    );
  }
}
